<template>
  <section class="template-dashboard-view templates-list-view">
    <div class="inner-navbar">
      <div class="left-title">
        <div class="icon-block">
          <div class="icon">
            <img
              src="@/assets/img/icons/menu/company-templates-2.svg"
              alt="icon"
              class="img-fluid"
            />
          </div>
          <div class="icon-text">
            <h3 class="title fw-normal text-dark mb-0 ls-05">
              Notification Templates
            </h3>
          </div>
        </div>
      </div>
      <div class="right-block">  
        <template v-if="!getIsMobile">
        <div class="right-search-input">
          <el-input
            placeholder="Search"
            prefix-icon="el-icon-search"
            size="small"
            v-model="search_string"
            @input="searchString"
            style="width: 170px"
            clearable
            class="search_input float-right"
          ></el-input>
        </div>
        <div class="right-search-input">
          <el-select
            v-model="selectedType"
            placeholder="Select Type"
            clearable
            style="margin-right: 5px; width: 170px; height:32px"
            @change="fetchAllCompanyTemplates()"
          >
            <el-option
              v-for="(item, index) of templateTypesArray"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="selected_entity_id"
            placeholder="Select Entities"
            filterable
            collapse-tags
            clearable
            style="width: 170px"
            v-if="selectedType == 'ENTITY'"
            @change="fetchAllCompanyTemplates()"
          >
            <el-option
              v-for="(item, index) of allEntities"
              v-bind:key="index"
              :label="item.name"
              :value="item._id"
            ></el-option>
          </el-select>
        </div>
        <!-- <div
            v-if="checkPermissionByPermissionName('createFormTemplate')"
            class="right-create-button"
          > -->
        <div
          style="margin-right: 5px"
          v-if="checkPermissionByPermissionName('createEmailTemplate')"
        >
          <el-tooltip content="Add new header_footer/global Template">
            <el-button
              class="entity-add-btn mr-10"
              @click="createNewParent"
              icon="el-icon-circle-plus-outline"
              style="height: 32px; margin-left:0px"
            ></el-button>
          </el-tooltip>
        </div>
        <div class="right-create-button">
          <!-- <router-link to="/template/create"> -->
          <button
            class="btn add-button float-right mt-0"
            @click="createTemplate"
            v-if="checkPermissionByPermissionName('createEmailTemplate')"
          >
          <span class="create-template">
            <i class="fa fa-plus-circle"></i>
            {{getIsMobile ? 'Create' : 'Create Template'}}
          </span>
          </button>
          <!-- </router-link> -->
        </div>
      </template>
      <template v-else>
        <el-row>
          <div style="display:flex;gap:10px">
        <div class="right-search-input" style="margin-top:15px">
          <el-input
            placeholder="Search"
            prefix-icon="el-icon-search"
            size="small"
            v-model="search_string"
            @input="searchString"
            style="width: 100%"
            clearable
            class="search_input float-right"
          ></el-input>
        </div>
        <div class="right-search-input" style="margin-top:10px">
          <el-select
            v-model="selectedType"
            placeholder="Select Type"
            clearable
            style="width: 100%; margin-top: 5px;"
            @change="fetchAllCompanyTemplates()"
          >
            <el-option
              v-for="(item, index) of templateTypesArray"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      </el-row>
      <e-row>
        <div style="display:flex;gap:10px">
        <div class="right-search-input" v-if="selectedType == 'ENTITY'">
          <el-select
            v-model="selected_entity_id"
            placeholder="Select Entities"
            filterable
            collapse-tags
            clearable
            style="width: 100%; margin-top: 5px;"
            @change="fetchAllCompanyTemplates()"
          >
            <el-option
              v-for="(item, index) of allEntities"
              v-bind:key="index"
              :label="item.name"
              :value="item._id"
            ></el-option>
          </el-select>
        </div>
        <div
          style="margin-right: 5px"
          v-if="checkPermissionByPermissionName('createEmailTemplate')"
        >
          <el-tooltip content="Add new header_footer/global Template">
            <el-button
              class="entity-add-btn mr-10"
              @click="createNewParent"
              icon="el-icon-circle-plus-outline"
              style="height: 32px; margin-left:0px"
            ></el-button>
          </el-tooltip>
        </div>
        <div class="right-create-button">
          <button
            class="btn add-button float-right mt-0"
            @click="createTemplate"
            v-if="checkPermissionByPermissionName('createEmailTemplate')"
          >
          <span class="create-template">
            <i class="fa fa-plus-circle"></i>
            {{getIsMobile ? 'Create' : 'Create Template'}}
          </span>
          </button>
        </div>
        </div>
        </e-row>
      </template>
    </div>
    </div>
    <div class="inner-navbar-content top">
      <div
        class="vue-data-table-default"
        v-loading="loading"
        :element-loading-text="loadingText"
      >
        <data-tables-server
          :data="data"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
        >
          <el-table-column
            label="Template Name"
            id="name"
            prop="name"
            min-width="140"
            sortable="custom"
          >
            <template v-if="scope.row.name" slot-scope="scope">
              <el-tooltip :content="scope.row.name">
                <span @click="onTemplateView(scope.row)">
                  <span v-if="scope.row.template_type === 'EMAIL'">
                    <i class="el-icon-message mr-1"></i>
                  </span>
                  <span v-else-if="scope.row.template_type === 'WA'">
                    <i class="el-icon-chat-dot-round mr-1">
                    </i></span>
                  {{ scope.row.name | truncate(15, "...") }}
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="Subject"
            id="subject"
            prop="subject"
            min-width="120"
            sortable="custom"
          >
            <template slot-scope="scope">
                <el-tooltip :content="scope.row.subject">
                <span v-if="scope.row.subject">
                  {{ scope.row.subject | truncate(15, "...") }}
                </span>
                <span v-else>--</span>
                </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="action" min-width="100px">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  Actions
                  <i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <a
                    v-if="
                      checkPermissionByPermissionName('previewEmailTemplate')
                    "
                    @click="onTemplateView(scope.row)"
                    style="border: none"
                    ><el-dropdown-item icon="el-icon-view" command="edit"
                      >Preview</el-dropdown-item
                    ></a
                  >
                  <a
                    v-if="checkPermissionByPermissionName('editEmailTemplate')"
                    @click="onEdit(scope.row)"
                    style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-edit-outline"
                      command="edit"
                      >Edit</el-dropdown-item
                    ></a
                  >
                  <a
                    v-if="
                      checkPermissionByPermissionName('duplicateEmailTemplate')
                    "
                    @click="openDuplicateTemplateModal(scope.row)"
                    style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-copy-document"
                      command="edit"
                      >Duplicate Template</el-dropdown-item
                    ></a
                  >
                  <a
                    v-if="
                      checkPermissionByPermissionName('deleteEmailTemplate')
                    "
                    @click="onDelete(scope.row)"
                    style="border: none"
                  >
                    <el-dropdown-item icon="el-icon-delete" command="delete"
                      >Delete</el-dropdown-item
                    ></a
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <el-table-column label="Type" id="category" min-width="150">
            <template slot-scope="scope">
              <span
                class="cursor-pointer"
                v-if="scope.row.type && scope.row.type == 'GLOBAL'"
              >
                <el-tooltip
                  content="Global Templates can be used for all Entity"
                >
                  <el-tag @click="chooseType('GLOBAL')" type="success"
                   size="mini" >Global</el-tag
                  >
                </el-tooltip>
              </span>
              <span
                class="cursor-pointer"
                v-else-if="scope.row.type && scope.row.type == 'PARENT'"
              >
                <el-tag @click="chooseType('PARENT')" type="danger" 
                 size="mini" >Header & Footer</el-tag
                >
              </span>
              <span
                class="cursor-pointer"
                v-if="scope.row.type == 'ENTITY' && scope.row.entity_id"
              >
                <el-tag @click="chooseType('ENTITY')"
                  size="mini">Entity -
                  {{ scope.row.entity_id.name | truncate(10, "...") }}</el-tag
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Description" id="category" min-width="160">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.description">
              <span>{{ scope.row.description | truncate(25, "...") }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="Last Modified"
            id="updated_at"
            min-width="170"
            sortable="custom"
          >
            <template slot-scope="scope" v-if="scope.row.updated_at">
              <span class="fs-8 d-inline-block pr-2 text-muted">
                {{ scope.row.updated_at | globalDateTimeFormat }}
              </span>
            </template>
          </el-table-column>
        </data-tables-server>
      </div>
    </div>
    <dialog-component
      v-loading.fullscreen.lock="duplicateLoading"
      :title="'Duplicate Template'"
      :visible="duplicateFromTemplateDialogVisible"
      :containerWidth="'25%'"
      @before-close="duplicateFromTemplateDialogVisible = false"
      v-draggable
    >
    <div class="m-tb-5">
      <label>Title :</label>
      <el-input
        type="text"
        placeholder="Title"
        v-model="duplicateTemplateName"
        @keydown.enter.native="onEnter"
      >
      </el-input>
      </div>
      <span slot="footer">
        <el-button @click="closeDuplicateTemplateModal">Cancel</el-button>
        <el-button
          :disabled="!duplicateTemplateName"
          type="primary"
          @click="duplicateTemplate()"
          >Confirm</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      v-loading.fullscreen.lock="duplicateLoading"
      :title="currentTemplateName"
      :visible="previewDialogVisible"
      @before-close="closepreviewDialogVisible"
      :containerMaxHeight="'600px'"
      :isShowFooter="false"
    >
      <div style="justify-content: center !important">
        <div
          style="margin-left: 50px"
          v-html="this.currentTemplateContent"
        ></div>
      </div>
    </dialog-component>
  </section>
</template>
  <script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import templateBuilderHelper from '@/mixins/templateBuilderHelper';
import entitiesHelper from '@/mixins/EntitiesHelper';
// import { fetchEntitiesByPagination } from "@/repo/entityRepo";
import { configs } from "@/assets/data/general_config.ts";
export default {
  name: "email-Templates-List",
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("companyTemplates", [
      "getAllCompanyTemplatesData",
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDuplicateFormTemplateError",
      "getDuplicateFormTemplateStatus",
    ]),
    ...mapGetters("entityEmailTemplate", [
      "getAllCompanyEmailTemplatesData",
      "getDeleteEmailTemplateStatus",
      "getDuplicateTemplateStatus",
      "getEmailTemplateError",
    ]),
    ...mapGetters("entities", [
      "getAddEntityToCompanyStatus",
      "getAllEntities",
    ]),
    ...mapGetters("auth", ["getActiveWorkspace"]),

    ...mapGetters("entityGroups", ["getSingleEntityGroupData"]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),

    ...mapGetters("auth", ["getAuthenticatedUser"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  mixins: [PermissionsHelper, NavigationHelper, templateBuilderHelper,entitiesHelper],
  async mounted() {
    this.loading = true;
    await this.fetchAllCompanyTemplates();
    await this.fetchEntities();
    this.loading = false;
  },
  props: ["entityIds"],
  data() {
    return {
      formTemplateId: null,
      currentEntityId: "",
      pageSizes: [10, 20, 50],
      duplicateTemplateName: "",
      duplicateLoading: false,
      duplicateFromTemplateDialogVisible: false,
      loading: false,
      selectedCompanyTemplate: {},
      total: 0,
      currentPage: 1,
      pageSize: 0,
      order_type: null,
      order_by: null,
      data: [],
      previewDialogVisible: false,
      timeline: null,
      search_string: "",
      temp_total: 0,
      selected_templates: [],
      currentTemplateContent: "",
      currentTemplateName: "",
      selected_entity_id: "",
      allEntities: [],
      templateType: "",
      loadingText: "Fetching templates... Please wait while...",
      templateTypesArray: [
        {
          name: "Entity",
          value: "ENTITY",
        },
        {
          name: "Header & Footer",
          value: "PARENT",
        },
        {
          name: "Global",
          value: "GLOBAL",
        },
      ],
      selectedType: "",
    };
  },
  beforeDestroy() {
    this.$store.commit(
      "navigationOpen/setCurrentMenu",
      {},
      {
        root: true,
      }
    );
  },
  methods: {
    openDuplicateTemplateModal(template) {
      this.duplicateTemplateName = template.name + "-Dup";
      this.formTemplateId = template._id;
      this.templateType = template.type || "ENTITY";
      if (template.type && template.type == "ENTITY") {
        this.currentEntityId = template.entity_id._id;
      }
      this.duplicateFromTemplateDialogVisible = true;
    },
    closepreviewDialogVisible(){
      this.previewDialogVisible=false;
    },
    closeDuplicateTemplateModal() {
      this.duplicateFromTemplateDialogVisible = false;
    },
    getTemplateSlug() {
      return this.duplicateTemplateName
        .trim()
        .toLowerCase()
        .split(" ")
        .join("-");
    },
    checkPermissions() {
      if (!this.checkPermissionByPermissionName("createFormTemplate")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for create template. Please contact owner.!",
        });
      }
    },
    async addToCompanyEntity() {
      try {
        this.loading = true;
        let params = {
          templates: this.selected_templates.flatMap((e) => e._id),
        };
        await this.$store.dispatch("entities/addCompanyEntity", params);
        if (this.getAddEntityToCompanyStatus) {
          this.loading = false;
          this.$notify.success({
            title: "Success",
            message: "Templates added to company profile successfully",
          });
          this.fetchAllCompanyTemplates();
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Error on adding templates",
          });
        }
      } catch (er) {
        console.log("addToCompanyEntity",er);
      }
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchAllCompanyTemplates();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchAllCompanyTemplates();
      }
    },
    handleSelectionChange(val) {
      this.selected_templates = [];
      this.selected_templates = val;
    },
    async fetchAllCompanyTemplates() {
      this.loading = true;
      if (this.pageSize == 0) {
        this.pageSize = 10;
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        getAll: true,
      };
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.order_type) {
        params.order_type = this.order_type;
      }

      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.selectedType) {
        params.type = this.selectedType;
        if (this.selectedType != "ENTITY") {
          this.selected_entity_id = "";
        }
      }
      if (this.selected_entity_id) {
        params.entity_id = this.selected_entity_id;
      }
      await this.$store.dispatch(
        "entityEmailTemplate/allEmailTemplates",
        params
      );

      // this.currentPage = this.getAllCompanyTemplatesData.page
      //   ? parseInt(this.getAllCompanyTemplatesData.page)
      //   : 1;
      this.data = [];
      if (this.getAllCompanyEmailTemplatesData) {
        this.data = this.getAllCompanyEmailTemplatesData.data;
        this.total = this.getAllCompanyEmailTemplatesData.total;
      }
      // this.total = this.getAllCompanyTemplatesData.total
      //   ? parseInt(this.getAllCompanyTemplatesData.total)
      //   : 0;
      // this.data = this.getAllCompanyTemplatesData.data || [];
      this.loading = false;
    },
    async refreshTemplates() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.fetchAllCompanyTemplates();
    },
    searchString() {
      this.pageSize = 10;
      this.page = 1;
      this.fetchAllCompanyTemplates();
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllCompanyTemplates();
      }
    },
    async goToEditTemplate(template) {
      // if (!this.checkPermissionByPermissionName("editFormTemplate")) {
      //   this.$notify.error({
      //     title: "Error",
      //     message:
      //       "Permission denied for edit template. Please contact owner! ",
      //   });
      // }
      // else {
      let query = this.getNavigationQuery(this.$route.query);
      if (template.type && template.type != "ENTITY") {
        return this.$router.push({
          name: "EditEmailHeaderFooterTemplate",
          params: { template_id: template._id },
          query,
        });
      }
      this.$router.push({
        name: "EditEmailTemplate",
        params: { template_id: template._id },
        query,
      });
      // }
    },

    async createTemplate() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        path: "/email-template/create",
        query,
      });
    },

    onEdit(template) {
      // if (
      //   !this.checkPermissionByPermissionNameFormTemplates(
      //     template,
      //     "editFormTemplate"
      //   )
      // ) {
      //   this.$notify.error({
      //     title: "Error",
      //     message:
      //       "Permission denied for edit template. Please contact owner! ",
      //   });
      // } else {
      this.goToEditTemplate(template);
      // }
    },
    async onTemplateView(template) {
      if (this.getIsMobile) {
        return this.$message.warning(
          "Preview is not available on small versions"
        );
      }
      let parentTemplate;
      if (template.type && template.type == "PARENT") {
        parentTemplate = configs.headerFooterTemplate;
        parentTemplate = parentTemplate.replace(
          "{{header_content}}",
          template.header_content
        );
        parentTemplate = parentTemplate.replace(
          "{{footer_content}}",
          template.footer_content
        );
      } else {
        let allEntityFields = await this.fetchEntityDetails(template.entity_id._id,true,false,false,false,true,false,template.entity_id);
        let content = this.parseEmailTemplate(/\[\[([^[\]]*)\]\]/g,template.content,allEntityFields,true);
        parentTemplate = configs.parentTemplate;
        parentTemplate = parentTemplate.replace(
          "{{content_data}}",
            content
        );
        parentTemplate = parentTemplate.replace(
          "{{company_name}}",
          this.getActiveWorkspace.name
        );
      }
      this.currentTemplateContent = parentTemplate;
      this.currentTemplateName = template.name;
      this.previewDialogVisible = true;
      // }
    },
    async duplicateTemplate() {
      this.duplicateLoading = true;
      let params = {
        name: this.duplicateTemplateName,
        templateId: this.formTemplateId,
      };
      if (this.type == "ENTITY") {
        params["entity_id"] = this.currentEntityId;
      }
      await this.$store.dispatch(
        "entityEmailTemplate/duplicateTemplate",
        params
      );
      this.duplicateLoading = false;
      if (this.getDuplicateTemplateStatus) {
        this.$notify.success({
          title: "Success",
          message: "Template Duplicated Successfully.",
        });
        this.duplicateFromTemplateDialogVisible = false;
        this.duplicateLoading = false;
        await this.fetchAllCompanyTemplates();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getEmailTemplateError
            ? this.getEmailTemplateError
            : "Error in duplicating form template",
        });
      }
    },
    async onDelete(row) {
      // if (
      //   !this.checkPermissionByPermissionNameFormTemplates(
      //     row,
      //     "deleteFormTemplate"
      //   )
      // ) {
      //   this.$notify.error({
      //     title: "Error",
      //     message:
      //       "Permission denied for delete templates. Please contact owner.!",
      //   });
      // }
      // else {
      this.$confirm("Are you sure to delete the Template?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteTemplate(row);
      });
      // }
    },
    async deleteTemplate(template) {
      let params = {
        id: template._id,
      };
      await this.$store.dispatch(
        "entityEmailTemplate/deleteEmailTemplate",
        params
      );
      if (this.getDeleteEmailTemplateStatus) {
        this.$notify.success({
          title: "Success",
          message: "Template deleted successfully",
        });
        this.refreshTemplates();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getTemplateDeleteError || "Error on deleting template",
        });
      }
    },

    async fetchEntities() {
      try {
        // this.Loading = true;
        let params = {
          get_all: true,
        };
        await this.$store.dispatch("entities/fetchEntities", params);
        if (this.getAllEntities) {
          this.allEntities = [...this.getAllEntities.data];
          // this.configureLoading = false;
        } else {
          // this.configureLoading = false;
        }
      } catch (err) {
        console.log("fetchEntities",err);
      }
    },
    onEnter(event) {
      if (event.keyCode === 13) {
        this.duplicateTemplate();
      }
    },
    async createNewParent() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        path: "/email-header-footer-template/create",
        query,
      });
    },
    chooseType(type) {
      if (this.selectedType == type) {
        this.selectedType = "";
      } else {
        this.selectedType = type;
      }
      this.fetchAllCompanyTemplates();
    },
  },
};
</script>
  <style lang="scss" scoped>
.template-dashboard-view {
  // width: 90%;
  // display: table;
  margin: 0 auto;
  .search_input {
    // height: 29px !important;
  }
  .inner-navbar-content {
    margin-top: 20px;
  }
  .guide-stats-block {
    padding: 1em 0 2em;
    .each-stat {
      position: relative;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 1em 1em 0.75em;
      color: #ffffff;
      overflow: hidden;
      min-height: 105px;
      &.stat-card-one {
        background-color: #ff83a1;
      }
      &.stat-card-two {
        background-color: #5673dc;
      }
      &.stat-card-three {
        background-color: #ffbd12;
      }
      .icon {
        position: relative;
        z-index: 3;
        img {
          max-height: 50px;
        }
      }
      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
      .stat-content {
        position: relative;
        z-index: 2;
      }
      h3 {
        font-size: 3em;
        font-weight: 300;
        line-height: 1;
        text-align: center;
      }
      p {
        position: absolute;
        text-align: right;
        bottom: 0;
        right: 0;
      }
    }
    .usage-guide {
      position: relative;
      min-height: 105px;
      display: flex;
      background-color: #ebf0f3;
      border-radius: 8px;
      padding: 1em 1em 0.75em;
      overflow: hidden;
      .content {
        position: relative;
        z-index: 2;
      }
      .icon-right {
        width: 200px;
        position: relative;
        z-index: 3;
      }
      h4 {
        font-size: 1em;
        margin: 0;
        font-weight: 400;
      }
      p {
        font-size: 0.75em;
        line-height: 1.3;
        margin: 0.25em 0 0.75em;
      }
      .el-button {
        font-size: 0.85em;
        padding: 0.35em 0.75em;
        background-color: #6979f8;
        &:hover {
          background-color: #4558e9;
        }
      }

      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
  
  <style lang="scss">
.templates-list-view {
  .el-table {
    td:first-child {
      .cell {
        text-overflow: unset !important;
      }
    }
  }
}
</style>
  <style lang="scss" scoped>
.inner-navbar {
  display: flex;
  justify-content: space-between;
  @media (max-width: 650px) {
    flex-direction: column;
    .left-title {
      margin-bottom: 10px;
    }
  }
  .left-title {
    .icon-block {
      display: flex;
      margin-top:10px;
      .icon {
        width: 30px;
        margin-right: 10px;
      }
      .icon-text {
      }
    }
  }
  .right-block {
    display: flex;
    .right-search-input {
      margin-right: 10px;
      // height: 50px;
      @media (max-width: 650px) {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
    .right-create-button {
    }
    @media (max-width: 650px) {
      flex-direction: column;
      align-items: end;
    }
  }
}
</style>
  <style lang="scss">
.form-template-list-mobile {
  .el-table__body {
    .el-table__row {
      & > td {
        &:first-child {
          padding: 0px;
        }
      }
    }
  }
}
.template-dashboard-view .add-button{
    color: var(--primary-contrast-color);
    background: var(--primary-color);
    border-color: var(--primary-color);
    height:32px !important;
    &:hover{
    color: var(--primary-contrast-color);
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
}
</style>
<style scoped>
.create-template {
  position:relative;
  bottom:3px;
  letter-spacing: 0px;
}
</style>
  